// ApplicationsPage.js

import React, { useEffect, useState } from 'react';
import Card from '../components/Card';
import './ApplicationsPage.css';
import SearchInput from '../components/SearchInput';
import { FaArrowUp, FaArrowDown } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import Services from '../services/Services';
import { CircularProgress } from '@mui/material';

export default function ApplicationsPage() {
    const { token } = useParams();
    const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
    const [responses, setResponses] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');

    const getPoiID = () => {
        Services.BolParaAboutToken(token)
            .then((res) => {
                if (res.data.status) {
                    getResponses(res.data.result.easypointID);
                }
            })
            .catch((err) => console.log(err));
    }

    const getResponses = (poiID) => {
        Services.GetBolParaResponses(token, poiID)
            .then((res) => {
                if (res.data.status) {
                    setResponses(res.data.result);
                } else {
                    setResponses([]);
                }
                setIsLoading(false);
            })
            .catch((err) => console.log(err));
    }

    const getStatusLabel = (status) => {
        switch (status) {
            case "1": return "Onaylandı";
            case "2": return "Reddedildi";
            case "3": return "Onay Bekliyor";
            default: return "Bilinmiyor";
        }
    };

    function formatTimestamp(timestamp) {
        const timestampInMilliseconds = timestamp * 1000;
        const date = new Date(timestampInMilliseconds);
        if (isNaN(date.getTime())) return 'Geçersiz Tarih';

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        return `${day}.${month}.${year}`;
    }

    useEffect(() => {
        getPoiID();
    }, [token]);

    const sortedData = [...responses].sort((a, b) => {
        if (!sortConfig.key) return 0;

        const aValue = a[sortConfig.key];
        const bValue = b[sortConfig.key];

        if (typeof aValue === 'string' && typeof bValue === 'string') {
            return sortConfig.direction === 'ascending'
                ? aValue.localeCompare(bValue)
                : bValue.localeCompare(aValue);
        }

        if (aValue < bValue) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
    });

    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    // Filter responses based on searchTerm
    const filteredData = sortedData.filter(data =>
        data.companyTitle.toLowerCase().includes(searchTerm.toLowerCase())
    );
    console.log(sortedData);

    function maskName(name) {
        if (!name) return ''; // Boş ya da undefined kontrolü
      
        // İlk harfi sakla, geri kalanı * ile değiştir
        return name[0] + '*'.repeat(name.length - 1);
      }

    return (
        <body>
            <div className='main'>
                <Card>
                    <div style={{ alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'row', display: 'flex', alignSelf: 'flex-start' }}>
                        <p style={{ color: '#494D59', fontWeight: '700', fontSize: '18px' }}>
                            Easy Point Bol Para - Başvurular
                        </p>
                    </div>
                    <div style={{ alignSelf: 'flex-start', marginBottom: '19px' }}>
                        <span style={{ color: '#64748B', fontWeight: '400', fontSize: '12px' }}>
                            {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. */}
                        </span>
                    </div>

                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '19px' }}>
                        <SearchInput onSearch={setSearchTerm} /> {/* Pass setSearchTerm as onSearch prop */}
                    </div>
                    {isLoading ?
                        <div style={{ width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center', display: 'flex', marginTop: '15%', marginBottom: '15%' }}>
                            <CircularProgress />
                        </div>
                        :
                        <>
                            {responses.length > 0 ?
                                <div className="table-container">
                                    <table className="custom-table">
                                        <thead>
                                            <tr>
                                                <th onClick={() => requestSort('data[0].name')}>
                                                    Başvuran K. {sortConfig.key === 'data[0].name' && (sortConfig.direction === 'ascending' ? <FaArrowUp size={12} className="sort-icon" /> : <FaArrowDown size={12} className="sort-icon" />)}
                                                </th>
                                                <th onClick={() => requestSort('companyTitle')}>
                                                    Marka Adı {sortConfig.key === 'companyTitle' && (sortConfig.direction === 'ascending' ? <FaArrowUp size={12} className="sort-icon" /> : <FaArrowDown size={12} className="sort-icon" />)}
                                                </th>
                                                <th onClick={() => requestSort('campaignID')}>
                                                    Kampanya Adı {sortConfig.key === 'campaignID' && (sortConfig.direction === 'ascending' ? <FaArrowUp size={12} className="sort-icon" /> : <FaArrowDown size={12} className="sort-icon" />)}
                                                </th>
                                                <th onClick={() => requestSort('createdDateTime')}>
                                                    Tarih {sortConfig.key === 'createdDateTime' && (sortConfig.direction === 'ascending' ? <FaArrowUp size={12} className="sort-icon" /> : <FaArrowDown size={12} className="sort-icon" />)}
                                                </th>
                                                <th onClick={() => requestSort('status')}>
                                                    Durum {sortConfig.key === 'status' && (sortConfig.direction === 'ascending' ? <FaArrowUp size={12} className="sort-icon" /> : <FaArrowDown size={12} className="sort-icon" />)}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredData.map((data) => (
                                                <tr key={data.id}>
                                                    <td><b>{maskName(data.data[0].name) + " " + maskName(data.data[0].lastname)}</b></td>
                                                    <td>{data.companyTitle}</td>
                                                    <td>{data.title}</td>
                                                    <td>{formatTimestamp(data.createdDateTime)}</td>
                                                    <td>{getStatusLabel(data.status)}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                :
                                <div style={{alignItems:'center',justifyContent:'center',display:'flex',width:'100%'}}>
                                    <p style={{fontSize:'12px'}}>Mevcut başvuru bulunamadı.</p>
                                </div>

                            }

                        </>

                    }
                </Card>
            </div>
        </body>

    );
}
