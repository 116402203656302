// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  background-color: #FAF6F5;
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family: "DM Sans", sans-serif;
  padding-top:5px;
  width: 100vw;
}

p {
  font-family: "DM Sans", sans-serif;
}

.main {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 25%;
}

@media only screen and (max-width: 768px) {
  .main {
    width: 60%;
  }
}

@media only screen and (max-width: 480px) {
  .main {
    width: 80%;
  }
}

.button {
  background-color: #DA3831;
  border-width: 0;
  width: 100%;
  height: 54px;
  border-radius: 10px;
  transition: background-color 0.3s ease;
  align-items: center;
  justify-content: center;
  display: flex;
}

.button p {
  font-family: "DM Sans", sans-serif;
  font-weight: 700;
  font-size: 14px;
  color: #FAF6F5;
}

.button:disabled {
  background-color: gray;
  cursor: not-allowed;
  opacity: 0.7;
}

.button:disabled:hover {
  background-color: gray;
}

.button:hover {
  background-color: #B8302A;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,kCAAkC;EAClC,eAAe;EACf,YAAY;AACd;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb,sBAAsB;EACtB,UAAU;AACZ;;AAEA;EACE;IACE,UAAU;EACZ;AACF;;AAEA;EACE;IACE,UAAU;EACZ;AACF;;AAEA;EACE,yBAAyB;EACzB,eAAe;EACf,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,sCAAsC;EACtC,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;AACf;;AAEA;EACE,kCAAkC;EAClC,gBAAgB;EAChB,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,sBAAsB;EACtB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,yBAAyB;EACzB,eAAe;AACjB","sourcesContent":["body {\n  background-color: #FAF6F5;\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  font-family: \"DM Sans\", sans-serif;\n  padding-top:5px;\n  width: 100vw;\n}\n\np {\n  font-family: \"DM Sans\", sans-serif;\n}\n\n.main {\n  align-items: center;\n  justify-content: center;\n  display: flex;\n  flex-direction: column;\n  width: 25%;\n}\n\n@media only screen and (max-width: 768px) {\n  .main {\n    width: 60%;\n  }\n}\n\n@media only screen and (max-width: 480px) {\n  .main {\n    width: 80%;\n  }\n}\n\n.button {\n  background-color: #DA3831;\n  border-width: 0;\n  width: 100%;\n  height: 54px;\n  border-radius: 10px;\n  transition: background-color 0.3s ease;\n  align-items: center;\n  justify-content: center;\n  display: flex;\n}\n\n.button p {\n  font-family: \"DM Sans\", sans-serif;\n  font-weight: 700;\n  font-size: 14px;\n  color: #FAF6F5;\n}\n\n.button:disabled {\n  background-color: gray;\n  cursor: not-allowed;\n  opacity: 0.7;\n}\n\n.button:disabled:hover {\n  background-color: gray;\n}\n\n.button:hover {\n  background-color: #B8302A;\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
