import React from 'react'

export default function Modal({ children, style, visible }) {

    return (
        <div style={{ width: '100%', height: '155%', position: 'absolute', alignItems: 'center', justifyContent: 'center',  display: visible ? 'flex':"none", backgroundColor: 'rgba(0, 0, 0, 0.5)',zIndex:999 }}>
            <div style={{ width: '60%', height: '35%', backgroundColor: '#fff', borderRadius: '12px',  display: 'flex', ...style }}>
                {children}
            </div>
        </div>

    )
}
