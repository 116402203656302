import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Button from '../components/Button';
import Modal from '../components/Modal';
import { RxCross1 } from "react-icons/rx";
import { Carousel } from 'react-responsive-carousel'; // Carousel import

import Services from '../services/Services';
import { CircularProgress } from '@mui/material';

import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Carousel styles

export default function PackageInfo() {
    const { token, campaignID } = useParams();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [campaign, setCampaign] = useState();
    const [isLoading, setIsLoading] = useState(true);

    const getCampaign = () => {
        Services.GetBolParaCampaign(token, campaignID)
            .then((res) => {
                if (res.data.status) {
                    setCampaign(res.data.result);
                } else {
                    setCampaign();
                }
                setIsLoading(false);
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        getCampaign();
    }, []);

    return (
        <body>
            <div className='main'>
                {isLoading ? (
                    <div style={{ marginTop: '35%' }}>
                        <CircularProgress />
                    </div>
                ) : (
                    <>
                        {campaign && (
                            <>
                                {campaign.images &&
                                    <Carousel
                                        showStatus={false}
                                        showArrows={false}
                                        autoPlay={true} 
                                        interval={3000}  
                                        infiniteLoop={true}
                                        showThumbs={false}
                                        showIndicators={false}>
                                        {campaign.images.map((image, index) => (
                                            <div key={index}>
                                                <img src={image} alt={`Image ${index}`}
                                                    style={{
                                                        height: '150px',
                                                        objectFit: 'contain',
                                                        width: '100%'
                                                    }} />
                                            </div>
                                        ))}
                                    </Carousel>
                                }

                                <div style={{ alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'row', display: 'flex', alignSelf: 'flex-start' }}>
                                    <p style={{ color: '#494D59', fontWeight: '700', fontSize: '18px' }}>{campaign.title} Bilgi</p>
                                </div>
                                <div style={{ alignSelf: 'flex-start', maxHeight: '200px', overflowY: 'auto' }}>
                                    <div dangerouslySetInnerHTML={{ __html: campaign.description }} style={{ whiteSpace: 'pre-wrap' }} />
                                </div>
                                <Button onClick={() => setIsModalOpen(true)} style={{ marginTop: '25%', width: '236px' }} variant={'border'} label={'QR Kodu Görüntüle'} />

                                <Modal visible={isModalOpen} style={{ height: '290px', width: '290px', marginTop: '25%' }}>
                                    <div style={{ width: '100%', height: '100%', padding: '15px', alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                                        <div style={{ width: '100%', alignItems: 'center', justifyContent: 'flex-end', display: 'flex' }}>
                                            <RxCross1 onClick={() => setIsModalOpen(false)} />
                                        </div>
                                        <div style={{ backgroundColor: 'black', marginTop: '10px', alignItems: 'center', justifyContent: 'center', display: 'flex', width: '169px', height: '169px', borderRadius: '8px' }}>
                                            <img
                                                style={{ width: '147px' }}
                                                src={`https://qr.easypointapi.com/?chl=https://stage-bolpara.easypoint.com.tr?token=${token}:${campaignID}`}
                                                alt='QR Code'
                                            />
                                        </div>
                                        <text style={{ color: '#64748B', fontWeight: '400', fontSize: '12px', marginTop: '14px' }}>QR Kodu</text>
                                        {/* <text style={{ color: '#64748B', fontWeight: '400', fontSize: '12px', marginTop: '14px' }}>QR Manuel Müşteri Kodu:</text> */}
                                        {/* <text style={{ color: '#454749', fontWeight: '700', fontSize: '20px' }}>ep.esnf5368</text> */}
                                    </div>
                                </Modal>
                            </>
                        )}
                    </>
                )}
            </div>
        </body>
    );
}
