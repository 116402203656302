import axios from "axios";
import { API_URL } from "./index";

export default
    {
        GetBolParaCompanies(token) {
            return axios({
                method: 'post',
                url: `${API_URL}flow/get-bol-para-companies`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                data: {
                    onlyHasCampaigns: true
                }
            });
        },
        GetBolParaCompanyCampaigns(token, companyID) {
            return axios({
                method: 'post',
                url: `${API_URL}flow/get-bolpara-campaigns`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                data: {
                    companyID: companyID
                }
            });
        },

        GetBolParaCampaign(token, campaignID) {
            return axios({
                method: 'post',
                url: `${API_URL}flow/get-bolpara-campaign`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                data: {
                    campaignID: campaignID
                }
            });
        },
        GetBolParaResponses(token,poiID) {
            return axios({
                method: 'post',
                url: `${API_URL}flow/get-bol-para-responses`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                data:{
                    poiID:poiID
                }
            });
        },

        BolParaAboutToken(token) {
            return axios({
                method: 'post',
                url: `${API_URL}flow/about-token`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            
            });
        },

    }