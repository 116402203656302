// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-container {
    overflow-x: auto;
    width: 100%;
    max-height: 45vh;
}

.custom-table {
    width: 100%;
    border-collapse: collapse;
}

.custom-table thead th {
    background-color: #EAECF0;
    cursor: pointer;
    padding: 10px;
    border-bottom: 1px solid #d0d0d0;
    text-align: left;
    font-weight: 500;
    color: #475467;
    font-size: 12px;
    min-width: 100px;
    position: sticky;
    top: 0;
}

.custom-table th,
.custom-table td {
    border-bottom: 1px solid #e0e0e0;
    padding: 8px;
    color: #475467;
    font-size: 12px;
    font-weight: 400;
    min-width: 100px;
}

.custom-table tbody tr:hover {
    background-color: #f9f9f9;
}

.custom-table td {
    text-align: left;
    min-width: 100px;
}

.sort-icon {
    margin-left: 5px;
    vertical-align: middle;
    color: #475467;
    /* İkon rengi */
    font-size: 12px;
    /* İkon boyutu */
}`, "",{"version":3,"sources":["webpack://./src/pages/ApplicationsPage.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,eAAe;IACf,aAAa;IACb,gCAAgC;IAChC,gBAAgB;IAChB,gBAAgB;IAChB,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,gBAAgB;IAChB,MAAM;AACV;;AAEA;;IAEI,gCAAgC;IAChC,YAAY;IACZ,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,sBAAsB;IACtB,cAAc;IACd,eAAe;IACf,eAAe;IACf,gBAAgB;AACpB","sourcesContent":[".table-container {\r\n    overflow-x: auto;\r\n    width: 100%;\r\n    max-height: 45vh;\r\n}\r\n\r\n.custom-table {\r\n    width: 100%;\r\n    border-collapse: collapse;\r\n}\r\n\r\n.custom-table thead th {\r\n    background-color: #EAECF0;\r\n    cursor: pointer;\r\n    padding: 10px;\r\n    border-bottom: 1px solid #d0d0d0;\r\n    text-align: left;\r\n    font-weight: 500;\r\n    color: #475467;\r\n    font-size: 12px;\r\n    min-width: 100px;\r\n    position: sticky;\r\n    top: 0;\r\n}\r\n\r\n.custom-table th,\r\n.custom-table td {\r\n    border-bottom: 1px solid #e0e0e0;\r\n    padding: 8px;\r\n    color: #475467;\r\n    font-size: 12px;\r\n    font-weight: 400;\r\n    min-width: 100px;\r\n}\r\n\r\n.custom-table tbody tr:hover {\r\n    background-color: #f9f9f9;\r\n}\r\n\r\n.custom-table td {\r\n    text-align: left;\r\n    min-width: 100px;\r\n}\r\n\r\n.sort-icon {\r\n    margin-left: 5px;\r\n    vertical-align: middle;\r\n    color: #475467;\r\n    /* İkon rengi */\r\n    font-size: 12px;\r\n    /* İkon boyutu */\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
