import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function PartnerCircle({ image, name, token, companyID, company }) {
    const navigate = useNavigate();

    const handleCompanyClick = () => {

        navigate(`/${token}/${companyID}`, { state: {company} });
    };

    return (
        <div
            onClick={handleCompanyClick}
            style={{
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                cursor: 'pointer',
                textAlign: 'center' // Ensure text is centered
            }}
        >
            <div
                key={1}
                style={{
                    backgroundColor: 'white',
                    width: '60px',
                    height: '60px',
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflow: 'hidden' // Ensure content stays within the circular div.
                }}
            >
                <img
                    src={image}
                    alt={name}
                    style={{
                        width: '65%',
                        height: '65%',
                        objectFit: 'contain' // Maintain aspect ratio
                    }}
                />
            </div>
            <p
                style={{
                    color: '#494D59',
                    fontSize: '12px',
                    fontWeight: '500',
                    maxWidth: '60px', // Limit width to match container
                    overflowWrap: 'break-word', // Ensure long words wrap
                    wordBreak: 'break-word', // Break long words
                    marginTop: '4px', // Space between image and text
                    whiteSpace: 'normal' // Allow wrapping of text
                }}
            >
                {name}
            </p>
        </div>
    );
}
