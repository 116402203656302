import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { IoArrowBackOutline } from "react-icons/io5";

function Layout({ children }) {
    const navigate = useNavigate();
    const location = useLocation();

    const handleBackClick = () => {
        navigate(-1); // Go back to the previous page
    };

    if (location.pathname !== '/') {
        return (
            <div>
                <div onClick={handleBackClick} style={styles.backButton}>
                    <IoArrowBackOutline />
                </div>
                <main>{children}</main>
            </div>
        );
    
    }
    else{
        return <main>{children}</main>;
    }
}

const styles = {
    backButton: {
        position: 'fixed',
        top: '10px',
        right: '10px',
        zIndex: 998,
        backgroundColor: '#fff',
        color: 'black',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        width: '32px',
        height: '32px',
        boxShadow: '0px 1px 2px 0px #1018280D',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex'
    },
};

export default Layout;
