// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.horizontal-scroll-container {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    scroll-behavior: smooth;
    scrollbar-width: none;
    -ms-overflow-style: none;
    width: 100%;
}

.horizontal-scroll-container::-webkit-scrollbar {
    display: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/HorizontalScroll.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gBAAgB;IAChB,mBAAmB;IACnB,uBAAuB;IACvB,qBAAqB;IACrB,wBAAwB;IACxB,WAAW;AACf;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".horizontal-scroll-container {\r\n    display: flex;\r\n    overflow-x: auto;\r\n    white-space: nowrap;\r\n    scroll-behavior: smooth;\r\n    scrollbar-width: none;\r\n    -ms-overflow-style: none;\r\n    width: 100%;\r\n}\r\n\r\n.horizontal-scroll-container::-webkit-scrollbar {\r\n    display: none;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
