import React from "react";
import './HorizontalScroll.css';

const HorizontalScroll = ({ itemList = [], style }) => {
    return (
        <div style={style} className="horizontal-scroll-container">
            {itemList.map((item, index) => (
                <div key={index} style={{ marginRight: '10px' }}>
                    {item}
                </div>
            ))}
        </div>
    );
};

export default HorizontalScroll;
