// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-input-container {
    display: flex;
    align-items: center;
    border: 1px solid #D0D5DD; /* Border rengi */
    border-radius: 8px; /* Köşe yuvarlama */
    padding: 8px; /* İç boşluk */
    width: 100%; /* Genişlik ayarlanabilir */
    max-width: 300px; /* Maksimum genişlik */
}

.search-icon {
    color: #667085; /* İkon rengi */
    margin-right: 8px; /* İkon ile input arasında boşluk */
    font-size: 18px; /* İkon boyutu */
}

.search-input {
    border: none; /* Input'ta ekstra border olmasın */
    outline: none; /* Tıklanınca mavi border olmasın */
    width: 100%; /* Input genişliği */
    font-size: 14px; /* Yazı boyutu */
}

.search-input::placeholder {
    color: #667085; /* Placeholder rengi */
}
`, "",{"version":3,"sources":["webpack://./src/components/SearchInput.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,yBAAyB,EAAE,iBAAiB;IAC5C,kBAAkB,EAAE,mBAAmB;IACvC,YAAY,EAAE,cAAc;IAC5B,WAAW,EAAE,2BAA2B;IACxC,gBAAgB,EAAE,sBAAsB;AAC5C;;AAEA;IACI,cAAc,EAAE,eAAe;IAC/B,iBAAiB,EAAE,mCAAmC;IACtD,eAAe,EAAE,gBAAgB;AACrC;;AAEA;IACI,YAAY,EAAE,mCAAmC;IACjD,aAAa,EAAE,mCAAmC;IAClD,WAAW,EAAE,oBAAoB;IACjC,eAAe,EAAE,gBAAgB;AACrC;;AAEA;IACI,cAAc,EAAE,sBAAsB;AAC1C","sourcesContent":[".search-input-container {\r\n    display: flex;\r\n    align-items: center;\r\n    border: 1px solid #D0D5DD; /* Border rengi */\r\n    border-radius: 8px; /* Köşe yuvarlama */\r\n    padding: 8px; /* İç boşluk */\r\n    width: 100%; /* Genişlik ayarlanabilir */\r\n    max-width: 300px; /* Maksimum genişlik */\r\n}\r\n\r\n.search-icon {\r\n    color: #667085; /* İkon rengi */\r\n    margin-right: 8px; /* İkon ile input arasında boşluk */\r\n    font-size: 18px; /* İkon boyutu */\r\n}\r\n\r\n.search-input {\r\n    border: none; /* Input'ta ekstra border olmasın */\r\n    outline: none; /* Tıklanınca mavi border olmasın */\r\n    width: 100%; /* Input genişliği */\r\n    font-size: 14px; /* Yazı boyutu */\r\n}\r\n\r\n.search-input::placeholder {\r\n    color: #667085; /* Placeholder rengi */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
