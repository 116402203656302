import React from 'react'

export default function Card({ children }) {
    return (
        <div style={{
            backgroundColor:'#fff',
            padding:'10px',
            borderRadius:'6px',
            border:'1px solid #F3F3F3',
            maxWidth:'100%',
        }}>
            {children}
        </div>
    )
}
