import React, { useEffect, useState } from 'react';
import BolPara from '../../src/assets/bolParaBg.png';
import CoinHand from '../../src/assets/coins-hand.png';
import Button from '../components/Button';
import HorizontalScroll from '../components/HorizontalScroll';
import PartnerCircle from '../components/PartnerCircle';
import { useNavigate, useParams } from 'react-router-dom';
import Services from '../services/Services';
import { CircularProgress } from '@mui/material';

export default function MainPage() {
    const navigate = useNavigate();
    const { token } = useParams(); // URL'den gelen token'i alır

    const [companies, setCompanies] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const handleRedirectToApplications = () => {
        navigate(`/${token}/applications`);
    };

    const getCompanies = () => {
        console.log(token);
        Services.GetBolParaCompanies(token)
            .then((res) => {
                if (res.data.status) {
                    setCompanies(res.data.result)
                }
                else {
                    setCompanies([])
                }
                setIsLoading(false)
            })
            .catch((err) => console.log(err));
    }

    useEffect(() => {
        getCompanies()
    }, []);

    const itemList = companies.map((company) => (
        <PartnerCircle key={company.id} image={company.logo} name={company.title} token={token} companyID={company.companyID} company={company} />
    ));

    return (
        <body>
            <div className='main'>
                {isLoading ?
                    <div style={{ marginTop: '35%' }}>
                        <CircularProgress />
                    </div >
                    :
                    <>
                        <img src={BolPara} alt="Bol Para" style={{ width: '287px' }} />

                        <div style={{ alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'row', display: 'flex', alignSelf: 'flex-start' }}>
                            <img src={CoinHand} alt="-" style={{ marginRight: '3px' }} />
                            <p style={{ color: '#EE7F2B', fontWeight: '700', fontSize: '18px' }}>Bol Para <text style={{ color: '#494D59' }}>Nedir?</text></p>
                        </div>
                        <div style={{ alignSelf: 'flex-start' }}>
                            <text style={{ color: '#64748B', fontWeight: '400', fontSize: '12px' }}>
                                {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. */}
                            </text>
                        </div>

                        <p style={{ color: '#494D59', fontWeight: '700', fontSize: '16px', alignSelf: 'flex-start' }}>Partner Markalarımız</p>

                        <HorizontalScroll style={{ marginBottom: '66px' }} itemList={itemList} />

                        <Button onClick={() => handleRedirectToApplications()} label={'Başvuruları Görüntüle'} />
                    </>
                }

            </div>

        </body>
    )
}
